<template>
  <section class="section">
    <div class="container">
      <h1 class="title">Przeciągnij i upuść dokument DOCX lub ODT aby go dodać do autopisarza</h1>
      <div class="columns">
        <div class="column is-half is-offset-one-quarter">
          <form id="autowriternewdocument" class="dropzone"></form>
        </div>
      </div>

      <base-message class="is-primary" v-if="hasSuccessfullySavedToAutoWriter">Liczba dodanych tekstów do autopisarza: {{ itemsWrittenAmount }}</base-message>
      <base-message class="is-danger" v-if="autowriterSaveDocumentsError">Wystąpił błąd dodawania do autopisarza: {{ autowriterSaveDocumentsError }}</base-message>

      <base-dialog fixed :show="isLoading" title="Trwa zapisywanie do kolejki autopisarza...">
        <base-spinner></base-spinner>
      </base-dialog>

      <section v-if="hasDocumentTexts && !isLoading">
        <div class="columns is-multiline" id="autowriternewdocumentoreview">
          <document-text-item v-for="(textDocument, index) in documentTexts" :key="index" :url="textDocument.url" :preface="textDocument.preface" :text="textDocument.text" :seo-title="textDocument.seoTitle" :seo-description="textDocument.seoDescription" :seo-keywords="textDocument.seoKeywords"></document-text-item>
        </div>
        <div class="columns">
          <div class="column is-offset-two-thirds is-one-third has-text-right">
            <form id="autowriteradddocument" @submit.prevent="submitAddToAutoWriter">
              <input type="hidden" name="autowriterdocumentid" value="">
              <span class="control">
              <button class="button is-link submit">Dodaj do autopisarza</button>
            </span>
            </form>
          </div>
        </div>
      </section>
    </div>
  </section>
</template>

<script>
import Dropzone from 'dropzone'
import DocumentTextItem from '@/components/newdocument/DocumentTextItem'
import jsonHelper from '@/library/jsonHelper'

export default {
  name: 'NewDocument',
  title: 'Importuj teksty z dokumentu',
  components: { DocumentTextItem },
  data () {
    return {
      documentTexts: [],
      uploadedDocumentId: null,
      itemsWrittenAmount: 0,
      autowriterSaveDocumentsError: null,
      isLoading: false,
      host: null,
      documentDropzone: null
    }
  },
  methods: {
    async submitAddToAutoWriter () {
      this.isLoading = true
      try {
        const response = await this.axios.post(this.documentAddToAutoWriterUrl, {
          uploadedFileName: this.uploadedDocumentId
        }, {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.token
          }
        })
        const responseData = response.data
        this.itemsWrittenAmount = responseData.itemsWrittenAmount
        this.documentTexts = []
        this.clearDropzoneFiles()
      } catch (error) {
        let errorMessage = error.message
        if (error.response) {
          errorMessage = error.response.data['hydra:description']
        }
        this.autowriterSaveDocumentsError = errorMessage || 'Zapis się nie powiódł z powodu nieznanego błędu'
      }
      this.isLoading = false
    },
    clearDropzoneFiles () {
      this.documentDropzone.removeAllFiles()
    }
  },
  watch: {
    $route: 'clearDropzoneFiles'
  },
  mounted () {
    this.host = this.$store.getters.getBackendApiUrl
    Dropzone.autoDiscover = false
    this.documentDropzone = new Dropzone('#autowriternewdocument', {
      url: this.documentUploadUrl,
      maxFilesize: 1,
      maxFiles: 1,
      acceptedFiles: 'application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.oasis.opendocument.text',
      dictDefaultMessage: 'Przeciągnij i upuść pliki tutaj lub kliknij i wybierz z dysku',
      headers: {
        Authorization: 'Bearer ' + this.$store.getters.token,
        Accept: 'application/ld+json'
      }
    })
    this.documentDropzone.on('maxfilesexceeded', function (file) {
      this.removeAllFiles()
      this.addFile(file)
    })
    this.documentDropzone.on('success', (file, response) => {
      const responseData = JSON.parse(response)
      this.autowriterSaveDocumentsError = null
      this.uploadedDocumentId = responseData.uploadedFileName
      this.documentTexts = responseData.texts || []
      this.itemsWrittenAmount = 0
    })
    this.documentDropzone.on('error', (file, response) => {
      try {
        if (jsonHelper.isValidJSON(response)) {
          const responseData = JSON.parse(response)
          this.autowriterSaveDocumentsError = responseData['hydra:description']
          this.documentTexts = response.texts || []
        }
      } catch (error) {
        this.autowriterSaveDocumentsError = 'Wczytanie i przetworzenie dokumentu nie powiodło się z powodu nieznanego błędu'
      }
      this.itemsWrittenAmount = 0
    })
  },
  computed: {
    documentUploadUrl () {
      return this.host + '/api/auto_writer_entry/upload_new_document'
    },
    documentAddToAutoWriterUrl () {
      return this.host + '/api/auto_writer_entry/save_uploaded_document'
    },
    hasDocumentTexts () {
      return !!this.documentTexts.length
    },
    hasSuccessfullySavedToAutoWriter () {
      return !!this.itemsWrittenAmount
    }
  }
}
</script>

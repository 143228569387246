<template>
  <teleport to="body">
    <div class="modal" :class="{ 'is-active': show }">
      <div v-if="show" @click="tryClose" class="modal-background"></div>
      <transition name="dialog">
        <div v-if="show" class="modal-content">
          <div class="box">
            <header>
              <slot name="header">
                <h2>{{ title }}</h2>
              </slot>
            </header>
            <section>
              <slot></slot>
            </section>

          </div>
          <div v-if="!fixed">
            <slot name="actions">
              <base-button @click="tryClose" aria-label="Zamknij wyskakujące okienko" class="modal-close is-large">×</base-button>
            </slot>
          </div>
        </div>
      </transition>
    </div>
  </teleport>
</template>

<script>
export default {
  name: 'BaseDialog',
  props: {
    show: {
      type: Boolean,
      required: true
    },
    title: {
      type: String,
      required: false
    },
    fixed: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['close'],
  methods: {
    tryClose () {
      if (this.fixed) {
        return
      }
      this.$emit('close')
    }
  }
}
</script>

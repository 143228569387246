<template>
  <div class="container columns">
    <form @submit.prevent="submitFilterQueryForm" class="column is-one-quarter is-offset-three-quarters">
      <div class="field">
        <label class="label is-hidden" for="email" aria-label="Filtruj po adresie url">Filtruj po adresie url</label>
        <div class="control">
          <input class="input" type="text" placeholder="Filtruj po adresie url" name="query" id="query" v-model.trim="query">
        </div>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: 'FilterQueryForm',
  data () {
    return {
      query: ''
    }
  },
  emits: ['submit'],
  methods: {
    submitFilterQueryForm () {
      this.$emit('submit', this.query)
    }
  }
}
</script>

<template>
  <div>
    <base-dialog :show="!!error" title="Błąd logowania" @close="handleError">
      {{ error }}
    </base-dialog>
    <base-dialog fixed :show="isLoading" title="Trwa logowanie...">
      <base-spinner></base-spinner>
    </base-dialog>

    <section class="section">
      <div class="container columns">
        <form @submit.prevent="submitForm" class="column is-half is-offset-one-quarter">
          <article class="message is-danger" v-if="!formIsValid">
            <div class="message-body">Wprowadź poprawny login i/lub hasło</div>
          </article>

          <h1 class="title">Zaloguj się do systemu</h1>

          <div class="field">
            <label class="label" for="email">Login</label>
            <div class="control">
              <input class="input" type="email" placeholder="Twój adres e-mail" name="email" id="email" v-model.trim="email" required autofocus>
            </div>
          </div>

          <div class="field">
            <label class="label" for="password">Hasło</label>
            <div class="control">
              <input class="input" type="password" name="password"  id="password" v-model.trim="password" placeholder="Twoje hasło" required>
            </div>
          </div>

          <div class="field is-grouped is-pulled-right">
            <div class="control">
              <base-button class="button is-primary">Zaloguj się</base-button>
            </div>
          </div>
        </form>
      </div>
    </section>
  </div>
</template>

<script>
import BaseDialog from '@/components/ui/BaseDialog'
import BaseSpinner from '@/components/ui/BaseLoadingSpinner'
export default {
  name: 'UserAuth',
  title: 'Zaloguj się',
  components: { BaseSpinner, BaseDialog },
  data () {
    return {
      email: '',
      password: '',
      formIsValid: true,
      mode: 'login',
      isLoading: false,
      error: null
    }
  },
  methods: {
    async submitForm () {
      this.formIsValid = true
      if (this.email === '' || !this.email.includes('@')) {
        this.formIsValid = false
        return
      }

      this.isLoading = true
      const actionPayload = {
        email: this.email,
        password: this.password
      }
      try {
        await this.$store.dispatch('login', actionPayload)
        const redirectUrl = '/' + (this.$route.query.redirect || 'newdocument')
        await this.$router.replace(redirectUrl)
      } catch (error) {
        this.error = error.message || 'Nie można się zalogować - przyczyna nieznana, spróbuj później'
      }
      this.isLoading = false
    },
    handleError () {
      this.error = null
    }
  }
}
</script>

<style scoped>
form {
  margin: 1rem;
  padding: 1rem;
}

.form-control {
  margin: 0.5rem 0;
}

label {
  font-weight: bold;
  margin-bottom: 0.5rem;
  display: block;
}

input,
textarea {
  display: block;
  width: 100%;
  font: inherit;
  border: 1px solid #ccc;
  padding: 0.15rem;
}

input:focus,
textarea:focus {
  border-color: #3d008d;
  background-color: #faf6ff;
  outline: none;
}
</style>

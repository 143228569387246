<template>
  <section class="section">
    <div class="container">
      <h1 class="title">Archiwum publikacji autopisarza</h1>
      <div class="table-container">
        <FilterQueryForm @submit="filterQuerySubmitted"></FilterQueryForm>
      </div>
      <div class="table-container" v-if="hasPublishedListItems">
        <table class="table is-striped">
          <thead>
          <tr>
            <th>Docelowy adres publikacji</th>
            <th>Data publikacji</th>

          </tr>
          </thead>
          <tbody>
          <tr v-for="publishedItem in publishedListItems" :key="publishedItem.id">
            <td><a :href="publishedItem.publishedOnUrl" rel="noreferrer noopener" target="_blank">{{ publishedItem.publishedOnUrl }}</a></td>
            <td>{{ formatDate(publishedItem.publishedOnDate) }}</td>
          </tr>
          </tbody>
        </table>
        <div class="is-clickable">
          <pagination v-model="page" :records="totalPaginationRecords" :per-page="20" @paginate="changedPage" :options="{ theme: 'bulma', texts:{
              count:'',
              first:'Pierwsza',
              last:'Ostatnia',
              nextPage:'>',
              nextChunk:'>>',
              prevPage:'<',
              prevChunk:'<<'
          }}"></pagination>
        </div>
      </div>
      <base-spinner v-else-if="isLoading"></base-spinner>
      <base-message class="is-primary" v-else>Archiwum jest puste. Czas coś dodać </base-message>
    </div>
    <div class="columns">
      <div class="column is-offset-two-thirds is-one-third">
        <button class="button is-info" @click="refreshPublishedList">Odśwież listę archiwum publikacji</button>
      </div>
    </div>
  </section>
</template>

<script>
import Pagination from 'v-pagination-3'
import FilterQueryForm from '@/components/ui/FilterQueryForm'

export default {
  name: 'PublishedList',
  title () {
    return `Archiwum publikacji autopisarza | strona ${this.page}`
  },
  components: {
    Pagination,
    FilterQueryForm
  },
  data () {
    return {
      isLoading: true,
      page: 1,
      filterQuery: ''
    }
  },
  computed: {
    publishedListItems () {
      return this.$store.getters['publishedList/publishedListItems']
    },
    hasPublishedListItems () {
      return !this.isLoading && this.$store.getters['publishedList/hasPublishedListItems']
    },
    totalPaginationRecords () {
      return this.$store.getters['publishedList/getPublishedCount']
    }
  },
  methods: {
    async loadPublishedListItems (pageNumber = 1, filterQuery = null) {
      this.isLoading = true
      this.filterQuery = filterQuery
      await this.$store.dispatch('publishedList/loadPublishedListItems', { page: pageNumber, filterQuery: this.filterQuery })
      await this.$store.dispatch('publishedList/retrievePublishedCount', { filterQuery: this.filterQuery })
      this.isLoading = false
    },
    refreshPublishedList () {
      this.loadPublishedListItems()
    },
    async changedPage () {
      await this.$router.push({ name: 'published_list', params: { page: this.page, filterQuery: this.filterQuery } })
      await this.loadPublishedListItems(this.page, this.filterQuery)
    },
    formatDate (dateString) {
      return dateString ? new Date(dateString).toISOString().slice(0, 10) : null
    },
    async filterQuerySubmitted (query, pageNumber = 1) {
      await this.loadPublishedListItems(pageNumber, query)
    }
  },
  async created () {
    const pageNumber = this.$route.params.page || 1
    await this.loadPublishedListItems(pageNumber, this.filterQuery)
    this.page = pageNumber
  }
}
</script>

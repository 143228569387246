export default {
  isValidJSON (text) {
    try {
      JSON.parse(text)
      return true
    } catch {
      return false
    }
  }
}

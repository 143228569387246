import { createStore } from 'vuex'
import authModule from '@/store/modules/auth/index'
import errorsListModule from '@/store/modules/errors/index'
import publishedListModule from '@/store/modules/published/index'
import enqueuedListModule from '@/store/modules/enqueued/index'

const store = createStore({
  modules: {
    auth: authModule,
    errorsList: errorsListModule,
    publishedList: publishedListModule,
    enqueuedList: enqueuedListModule
  },
  state () {
    return {
      backendApiUrl: process.env.VUE_APP_API_HOST
    }
  },
  getters: {
    getBackendApiUrl (state) {
      return state.backendApiUrl
    }
  }
})

export default store

import axios from 'axios'

export default {
  namespaced: true,
  state () {
    return {
      enqueuedListItems: [],
      enqueuedCounter: 0
    }
  },
  mutations: {
    setEnqueuedListItems (state, payload) {
      state.enqueuedListItems = payload
    },
    setEnqueuedCounter (state, payload) {
      state.enqueuedCounter = payload || 0
    }
  },
  getters: {
    enqueuedListItems (state) {
      return state.enqueuedListItems
    },
    hasEnqueuedListItems (state) {
      return state.enqueuedListItems && state.enqueuedListItems.length > 0
    },
    getEnqueuedCount (state) {
      return state.enqueuedCounter
    }
  },
  actions: {
    async retrieveEnqueuedCount (context, payload) {
      if (!context.rootGetters.isAuthenticated) {
        return 0
      }
      const host = context.rootGetters.getBackendApiUrl
      try {
        const response = await axios.get(`${host}/api/auto_writer_entries?isPublished=false&isEnqueued=false&publishFailuresCount[lte]=10&itemsPerPage=0`, {
          headers: {
            Authorization: 'Bearer ' + context.rootGetters.token
          }
        })
        const responseData = response.data
        context.commit('setEnqueuedCounter', +responseData['hydra:totalItems'])
      } catch (error) {
        let errorMessage = error.message
        if (error.response) {
          errorMessage = error.response.data['hydra:description']
        }
        throw new Error(errorMessage || 'Nie udało się pobrać informacji o ilości błędów')
      }
    },
    async deleteEnqueuedItem (context, payload) {
      const itemId = payload.id || null
      if (itemId) {
        const enqueuedListItems = context.getters.enqueuedListItems
        const host = context.rootGetters.getBackendApiUrl
        try {
          await axios.delete(`${host}/api/auto_writer_entries/${itemId}`, {
            headers: {
              Authorization: 'Bearer ' + context.rootGetters.token
            }
          })
          context.commit('setEnqueuedListItems', enqueuedListItems.filter(item => item.id !== itemId))
          context.dispatch('retrieveEnqueuedCount')
        } catch (error) {
          let errorMessage = error.message
          if (error.response) {
            errorMessage = error.response.data['hydra:description']
          }
          throw new Error(errorMessage || 'Nie udało się usunąć tekstu z kolejki dodawania')
        }
      }
    },
    async loadEnqueuedListItems (context, payload) {
      const page = payload.page || 1
      const host = context.rootGetters.getBackendApiUrl
      try {
        const response = await axios.get(`${host}/api/auto_writer_entries?isPublished=false&isEnqueued=false&publishFailuresCount[lte]=10&order[id]&page=${page}`, {
          headers: {
            Authorization: 'Bearer ' + context.rootGetters.token
          }
        })
        const responseData = response.data
        const enqueuedListItems = []
        const responseItems = responseData['hydra:member']
        for (const key in responseItems) {
          const errorItem = {
            id: responseItems[key].id,
            entryTargetUrl: responseItems[key].entryTargetUrl
          }
          enqueuedListItems.push(errorItem)
        }
        context.commit('setEnqueuedListItems', enqueuedListItems)
      } catch (error) {
        let errorMessage = error.message
        if (error.response) {
          errorMessage = error.response.data['hydra:description']
        }
        throw new Error(errorMessage || 'Nie udało się pobrać danych o zakolejkowanych publikacjach')
      }
    }
  }
}

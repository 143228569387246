<template>
  <section class="section">
    <div class="container">
      <h1 class="title">Kolejka zaplanowanych tekstów do dodania</h1>
      <div class="table-container" v-if="hasEnqueuedListItems">
        <table class="table is-striped">
          <thead>
          <tr>
            <th>Docelowy adres publikacji</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="enqueuedItem in enqueuedListItems" :key="enqueuedItem.id">
            <td><a :href="enqueuedItem.entryTargetUrl" rel="noreferrer noopener" target="_blank">{{ enqueuedItem.entryTargetUrl }}</a></td>
            <td><button class="button is-danger is-small is-rounded" @click="deleteItem(enqueuedItem.id)">usuń</button></td>
          </tr>
          </tbody>
        </table>
        <div class="is-clickable">
          <pagination v-model="page" :records="totalPaginationRecords" :per-page="20" @paginate="changedPage" :options="{ theme: 'bulma', texts:{
              count:'',
              first:'Pierwsza',
              last:'Ostatnia',
              nextPage:'>',
              nextChunk:'>>',
              prevPage:'<',
              prevChunk:'<<'
          }}"></pagination>
        </div>
      </div>
      <base-spinner v-else-if="isLoading"></base-spinner>
      <base-message class="is-primary" v-else>W tej chwili nie masz nic zakolejkowanego do dodania. </base-message>
    </div>
    <div class="columns">
      <div class="column is-offset-two-thirds is-one-third">
        <button class="button is-info" @click="refreshEnqueuedList">Odśwież kolejkę</button>
      </div>
    </div>
  </section>
</template>

<script>
import Pagination from 'v-pagination-3'

export default {
  name: 'EnqueuedList',
  title () {
    return `Kolejka tekstów do dodania | strona ${this.page}`
  },
  components: {
    Pagination
  },
  data () {
    return {
      isLoading: true,
      page: 1
    }
  },
  computed: {
    enqueuedListItems () {
      return this.$store.getters['enqueuedList/enqueuedListItems']
    },
    hasEnqueuedListItems () {
      return !this.isLoading && this.$store.getters['enqueuedList/hasEnqueuedListItems']
    },
    totalPaginationRecords () {
      return this.$store.getters['enqueuedList/getEnqueuedCount']
    }
  },
  methods: {
    async loadEnqueuedListItems (pageNumber = 1) {
      this.isLoading = true
      await this.$store.dispatch('enqueuedList/loadEnqueuedListItems', { page: pageNumber })
      await this.$store.dispatch('enqueuedList/retrieveEnqueuedCount')
      this.isLoading = false
    },
    refreshEnqueuedList () {
      this.loadEnqueuedListItems()
    },
    async changedPage () {
      await this.$router.push({ name: 'enqueued_list', params: { page: this.page } })
      await this.loadEnqueuedListItems(this.page)
    },
    deleteItem (itemId) {
      const deleteConfirmation = confirm('Czy na pewno usunąć tą pozycję z kolejki do dodania?')
      if (deleteConfirmation) {
        this.$store.dispatch('enqueuedList/deleteEnqueuedItem', { id: itemId })
      }
    }
  },
  async created () {
    const pageNumber = this.$route.params.page || 1
    await this.loadEnqueuedListItems(pageNumber)
    this.page = pageNumber
  }
}
</script>

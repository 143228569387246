<template>
  <nav class="navbar is-spaced" role="navigation" aria-label="main navigation">
    <div class="navbar-brand">
      <router-link v-if="isLoggedIn" :to="{ name: 'new_document'}" class="navbar-item"><img src="https://netmove.pl/img/logo.png" width="154" height="48"></router-link>
      <router-link v-else :to="{ name: 'auth'}" class="navbar-item"><img src="https://netmove.pl/img/logo.png" width="154" height="48"></router-link>
    </div>
    <div class="navbar-menu" v-if="isLoggedIn">
      <div class="navbar-start">
        <p class="control">
          <router-link :to="{ name: 'new_document'}" class="button is-primary">Importuj z DOCX/ODT</router-link>
        </p>
        <router-link :to="{ name: 'errors_list'}" class="navbar-item">Błędy dodawania <button class="button is-danger is-rounded is-normal ml-1 has-text-weight-bold" v-if="autoWriterErrorsCount">{{ autoWriterErrorsCount }}</button></router-link>
        <router-link :to="{ name: 'enqueued_list'}" class="navbar-item">Kolejka dodawania</router-link>
        <router-link :to="{ name: 'published_list'}" class="navbar-item">Archiwum publikacji</router-link>
      </div>
      <div class="navbar-end">
        <div class="navbar-item">
          <div class="buttons">
            <span class="navbar-item">{{ welcomeUserText }}</span>
            <base-button @click="logout" class="button is-light is-clickable"><strong>Wyloguj się</strong></base-button>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'TheHeader',
  data () {
    return {
      loggedInUserName: null
    }
  },
  computed: {
    welcomeUserText () {
      const userName = this.$store.getters.userName
      if (userName) {
        return 'Witaj ' + userName + '!'
      }
      return ''
    },
    isLoggedIn () {
      return this.$store.getters.isAuthenticated
    },
    autoWriterErrorsCount () {
      return this.$store.getters['errorsList/getErrorsCount']
    }
  },
  watch: {
    autoWriterErrorsCount (newErrorsCount, oldErrorsCount) {
      // if we got more errors incoming automatically refresh list of errors
      if (newErrorsCount > oldErrorsCount) {
        this.$store.dispatch('errorsList/loadErrorsListItems', { page: 1 })
      }
    }
  },
  methods: {
    logout () {
      this.$store.dispatch('logout')
      this.$router.replace('/auth')
    }
  },
  mounted () {
    this.$store.dispatch('errorsList/retrieveErrorsCount', { filterQuery: '' })
    setInterval(() => {
      this.$store.dispatch('errorsList/retrieveErrorsCount')
    }, 20000) // refresh every 20 seconds
  }
}
</script>

<style scoped>
</style>

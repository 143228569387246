<template>
  <article class="message" :class="className">
    <div class="message-body">
      <slot></slot>
    </div>
  </article>
</template>

<script>
export default {
  name: 'BaseMessage',
  props: ['className']
}
</script>

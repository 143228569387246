import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import titleMixin from './mixins/MetaTitleMixin'
import axios from 'axios'
import VueAxios from 'vue-axios'

import BaseButton from '@/components/ui/BaseButton'
import BaseLoadingSpinner from '@/components/ui/BaseLoadingSpinner'
import BaseDialog from '@/components/ui/BaseDialog'
import BaseMessage from '@/components/ui/BaseMessage'

require('@/assets/main.scss')
require('dropzone/dist/dropzone.css')

const vue = createApp(App)

vue.use(store)
vue.use(router)
vue.mixin(titleMixin, {
  suffix: ' | Netmove autopisarz'
})
vue.use(VueAxios, axios)

axios.interceptors.response.use(function (response) {
  return response
}, function (error) {
  if (error.response.status === 401) {
    store.dispatch('logout')
    router.replace('/auth')
  }
  return Promise.reject(error)
})

vue.component('base-button', BaseButton)
vue.component('base-spinner', BaseLoadingSpinner)
vue.component('base-dialog', BaseDialog)
vue.component('base-message', BaseMessage)

vue.mount('#app')

function getTitle (vm) {
  const { title } = vm.$options
  if (title) {
    return typeof title === 'function'
      ? title.call(vm)
      : title
  }
}
function setTitle (title) {
  if (title) {
    document.title = title + ' ' + process.env.VUE_APP_META_TITLE_SUFFIX
  }
}

export default {
  mounted () {
    setTitle(getTitle(this))
  },
  updated () {
    setTitle(getTitle(this))
  }
}

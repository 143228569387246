<template>
  <the-header></the-header>
  <main class="container is-fullhd is-fluid" role="main">
    <section class="body">
      <router-view v-slot="slotProps">
        <transition name="route" mode="out-in">
          <component :is="slotProps.Component"></component>
        </transition>
      </router-view>
    </section>
  </main>
</template>
<script>
import TheHeader from '@/components/layout/TheHeader'
export default {
  name: 'App',
  components: { TheHeader },
  created () {
    this.$store.dispatch('tryLogin')
  },
  computed: {
    didAutoLogout () {
      return this.$store.getters.didAutoLogout
    }
  },
  watch: {
    didAutoLogout (curValue, oldValue) {
      if (curValue && curValue !== oldValue) {
        this.$router.replace('/auth')
      }
    }
  }
}
</script>
<style lang="scss">
  .route-enter-from {
    opacity: 0;
    transform: translateY(-30px);
  }
  .route-leave-to {
    opacity: 0;
    transform: translateY(30px);
  }
  .route-enter-active {
    transition: all 0.3s ease-out;
  }
  .route-leave-active {
    transition: all 0.3s ease-in;
  }
  .route-enter-to,
  .route-leave-from {
    opacity: 1;
    transform: translateY(0);
  }
</style>

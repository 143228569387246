import actions from '@/store/modules/auth/actions'

export default {
  state () {
    return {
      userId: null,
      token: null,
      userName: null,
      didAutoLogout: false
    }
  },
  mutations: {
    setUser (state, payload) {
      state.token = payload.token
      state.userId = payload.userId
      state.userName = payload.userName
      state.didAutoLogout = false
    },
    setAutoLogout (state) {
      state.didAutoLogout = true
    }
  },
  getters: {
    userId (state) {
      return state.userId
    },
    userName (state) {
      return state.userName
    },
    token (state) {
      return state.token
    },
    isAuthenticated (state) {
      return !!state.token
    },
    didAutoLogout (state) {
      return state.didAutoLogout
    }
  },
  actions
}

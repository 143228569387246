import axios from 'axios'

export default {
  namespaced: true,
  state () {
    return {
      publishedListItems: [],
      publishedCounter: 0
    }
  },
  mutations: {
    setPublishedListItems (state, payload) {
      state.publishedListItems = payload
    },
    setPublishedCounter (state, payload) {
      state.publishedCounter = payload || 0
    }
  },
  getters: {
    publishedListItems (state) {
      return state.publishedListItems
    },
    hasPublishedListItems (state) {
      return state.publishedListItems && state.publishedListItems.length > 0
    },
    getPublishedCount (state) {
      return state.publishedCounter
    }
  },
  actions: {
    async retrievePublishedCount (context, payload) {
      if (!context.rootGetters.isAuthenticated) {
        return 0
      }
      const filterQuery = (payload !== undefined && payload.filterQuery) ? `&filterbyurl=${payload.filterQuery}` : ''
      const host = context.rootGetters.getBackendApiUrl
      try {
        const response = await axios.get(`${host}/api/auto_writer_entries?isPublished=true&itemsPerPage=0${filterQuery}`, {
          headers: {
            Authorization: 'Bearer ' + context.rootGetters.token
          }
        })
        const responseData = response.data
        context.commit('setPublishedCounter', +responseData['hydra:totalItems'])
      } catch (error) {
        let errorMessage = error.message
        if (error.response) {
          errorMessage = error.response.data['hydra:description']
        }
        throw new Error(errorMessage || 'Nie udało się pobrać informacji o ilości archiwalnych publikacji')
      }
    },
    async loadPublishedListItems (context, payload) {
      const page = payload.page || 1
      const filterQuery = (payload.filterQuery) ? `&filterbyurl=${payload.filterQuery}` : ''
      const host = context.rootGetters.getBackendApiUrl
      try {
        const response = await axios.get(`${host}/api/auto_writer_entries?isPublished=true&order[publishedOnDate]&page=${page}${filterQuery}`, {
          headers: {
            Authorization: 'Bearer ' + context.rootGetters.token
          }
        })
        const responseData = response.data
        const publishedListItems = []
        const responseItems = responseData['hydra:member']
        for (const key in responseItems) {
          const errorItem = {
            id: responseItems[key].id,
            publishedOnUrl: responseItems[key].publishedOnUrl,
            publishedOnDate: responseItems[key].publishedOnDate
          }
          publishedListItems.push(errorItem)
        }
        context.commit('setPublishedListItems', publishedListItems)
      } catch (error) {
        let errorMessage = error.message
        if (error.response) {
          errorMessage = error.response.data['hydra:description']
        }
        throw new Error(errorMessage || 'Nie udało się pobrać danych o archiwum publikacji')
      }
    }
  }
}

<template>
  <div class="column is-one-third">
    <div class="card">
      <div class="card-content">
        <div class="content">
          <p><strong class="is-size-5">Cel publikacji</strong>: <a :href="url" rel="noopener noreferrer" target="_blank">{{ url }}</a></p>
          <p v-if="preface"><strong class="is-size-5">Zajawka</strong>: <span v-html="preface" class="is-size-7"></span></p>
          <article v-if="text"><strong class="is-size-5">Treść publikacji</strong>: <span v-html="text" class="is-size-7"></span></article>
          <p v-if="seoTitle"><strong class="is-size-5">SEO tytuł</strong>: <span class="is-size-7">{{ seoTitle }}</span></p>
          <p v-if="seoDescription"><strong class="is-size-5">SEO opis</strong>: <span class="is-size-7">{{ seoDescription }}</span></p>
          <p v-if="seoKeywords"><strong class="is-size-5">SEO słowa kluczowe</strong>: <span class="is-size-7">{{ seoKeywords }}</span></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DocumentTextItem',
  props: ['url', 'preface', 'text', 'seoTitle', 'seoDescription', 'seoKeywords']
}
</script>

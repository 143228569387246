import { createRouter, createWebHistory } from 'vue-router'

import NewDocument from '@/views/NewDocument'
import ErrorsList from '@/views/ErrorsList'
import UserAuth from '@/views/auth/UserAuth'
import PublishedList from '@/views/PublishedList'
import EnqueuedList from '@/views/EnqueuedList'

import store from '@/store/index'

const routes = [
  {
    path: '/',
    redirect: '/newdocument'
  },
  {
    path: '/newdocument',
    name: 'new_document',
    component: NewDocument,
    meta: { requiresAuth: true }
  },
  {
    path: '/autowriter/errors/:page?',
    name: 'errors_list',
    props: true,
    component: ErrorsList,
    meta: { requiresAuth: true }
  },
  {
    path: '/autowriter/published/:page?',
    name: 'published_list',
    props: true,
    component: PublishedList,
    meta: { requiresAuth: true }
  },
  {
    path: '/autowriter/enqueued/:page?',
    name: 'enqueued_list',
    props: true,
    component: EnqueuedList,
    meta: { requiresAuth: true }
  },
  {
    path: '/auth',
    name: 'auth',
    component: UserAuth,
    meta: { requiresUnAuth: true }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// ensure user is authenticated wherever required
router.beforeEach((to, _, next) => {
  if (to.meta.requiresAuth && !store.getters.isAuthenticated) {
    next('/auth')
  } else if (to.meta.requiresUnAuth && store.getters.isAuthenticated) {
    next('/')
  }
  next()
})
export default router

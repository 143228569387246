import jwtDecode from 'jwt-decode'
import axios from 'axios'

let timer

export default {
  async login (context, payload) {
    return context.dispatch('auth', payload)
  },
  async auth (context, payload) {
    const url = context.rootGetters.getBackendApiUrl + '/api/dologin'
    try {
      const response = await axios.post(url, {
        username: payload.email,
        password: payload.password
      })
      const responseData = response.data

      const decodedToken = jwtDecode(responseData.token)
      const now = new Date().getTime()
      const expirationDate = +decodedToken.exp * 1000 // need in milliseconds
      const expiresIn = expirationDate - now // need in milliseconds as difference between now and this expiration future moment

      localStorage.setItem('token', responseData.token)
      localStorage.setItem('userId', responseData.userId)
      localStorage.setItem('userName', responseData.userName)
      localStorage.setItem('tokenExpiration', expirationDate)

      timer = setTimeout(() => {
        context.dispatch('autoLogout')
      }, expiresIn)

      context.commit('setUser', {
        token: responseData.token,
        userId: responseData.userId,
        userName: responseData.userName
      })
    } catch (error) {
      let errorMessage = error.message
      if (error.response) {
        errorMessage = error.response.data.message
      }
      throw new Error(errorMessage || 'Logowanie nie powiodło się, spróbuj później')
    }
  },
  tryLogin (context) {
    const token = localStorage.getItem('token')
    const userId = localStorage.getItem('userId')
    const userName = localStorage.getItem('userName')
    const tokenExpiration = localStorage.getItem('tokenExpiration')

    const expiresIn = +tokenExpiration - new Date().getTime()

    if (expiresIn < 0) {
      return
    }

    timer = setTimeout(() => {
      context.dispatch('autoLogout')
    }, expiresIn)

    if (token && userId) {
      context.commit('setUser', {
        token: token,
        userId: userId,
        userName: userName
      })
    }
  },
  logout (context) {
    localStorage.removeItem('token')
    localStorage.removeItem('userId')
    localStorage.removeItem('userName')
    localStorage.removeItem('tokenExpiration')

    clearTimeout(timer)

    context.commit('setUser', {
      token: null,
      userId: null,
      userName: null
    })
  },
  autoLogout (context) {
    context.dispatch('logout')
    context.commit('setAutoLogout')
  }
}

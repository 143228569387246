<template>
  <section class="section">
    <div class="container">
      <h1 class="title">Lista błędów autopisarza</h1>
      <div class="table-container">
        <FilterQueryForm @submit="filterQuerySubmitted"></FilterQueryForm>
      </div>
      <div class="table-container" v-if="hasErrorsListItems">
        <table class="table is-striped">
          <thead>
          <tr>
            <th><abbr title="Identyfikator pozycji w aplikacji auto-pisarza">ID</abbr></th>
            <th>Docelowy adres publikacji</th>
            <th>Tytuł tekstu</th>
            <th>Komunikat błędu</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="error in errorsListItems" :key="error.id">
            <td class="is-vcentered">#{{ error.id }}</td>
            <td class="is-vcentered"><a :href="error.targetUrl" rel="noreferrer noopener" target="_blank">{{ error.targetUrl }}</a></td>
            <td class="is-vcentered"><span v-html="error.entryTitle"></span></td>
            <td class="is-vcentered"><div class="notification is-light is-danger">{{ error.publishedError}}</div></td>
            <td class="is-vcentered"><button class="button is-link is-normal is-rounded" @click="dismissItem(error.id)">zatwierdź</button></td>
          </tr>
          </tbody>
        </table>
        <div class="is-clickable">
          <pagination v-model="page" :records="totalPaginationRecords" :per-page="20" @paginate="changedPage" :options="{ theme: 'bulma', texts:{
              count:'',
              first:'Pierwsza',
              last:'Ostatnia',
              nextPage:'>',
              nextChunk:'>>',
              prevPage:'<',
              prevChunk:'<<'
          }}"></pagination>
        </div>
      </div>
      <base-spinner v-else-if="isLoading"></base-spinner>
      <base-message class="is-primary" v-else>Świetnie! Wszystkie wpisy autopisarza zostały dodane poprawnie.</base-message>
    </div>
    <div class="columns">
      <div class="column is-offset-two-thirds is-one-third">
        <button class="button is-info" @click="refreshErrorsList">Odśwież listę błędów</button>
      </div>
    </div>
  </section>
</template>

<script>
import Pagination from 'v-pagination-3'
import FilterQueryForm from '@/components/ui/FilterQueryForm'

export default {
  name: 'ErrorsList',
  title () {
    return `Błędy dodawania do autopisarza | strona ${this.page}`
  },
  components: {
    Pagination,
    FilterQueryForm
  },
  data () {
    return {
      isLoading: true,
      page: 1,
      filterQuery: ''
    }
  },
  computed: {
    errorsListItems () {
      return this.$store.getters['errorsList/errorsListItems']
    },
    hasErrorsListItems () {
      return !this.isLoading && this.$store.getters['errorsList/hasErrorsListItems']
    },
    totalPaginationRecords () {
      return this.$store.getters['errorsList/getErrorsCount']
    }
  },
  methods: {
    async loadErrorListItems (pageNumber = 1, filterQuery = null) {
      this.isLoading = true
      this.filterQuery = filterQuery
      await this.$store.dispatch('errorsList/loadErrorsListItems', { page: pageNumber, filterQuery: filterQuery })
      await this.$store.dispatch('errorsList/retrieveErrorsCount', { filterQuery: this.filterQuery })
      this.isLoading = false
    },
    refreshErrorsList () {
      this.loadErrorListItems()
    },
    dismissItem (itemId) {
      this.$store.dispatch('errorsList/dismissErrorItem', { id: itemId })
    },
    async changedPage () {
      await this.$router.push({ name: 'errors_list', params: { page: this.page, filterQuery: this.filterQuery } })
      await this.loadErrorListItems(this.page, this.filterQuery)
    },
    async filterQuerySubmitted (query, pageNumber = 1) {
      await this.loadErrorListItems(pageNumber, query)
    }
  },
  async created () {
    const pageNumber = this.$route.params.page || 1
    await this.loadErrorListItems(pageNumber, this.filterQuery)
    this.page = pageNumber
  }
}
</script>

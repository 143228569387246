import axios from 'axios'

export default {
  namespaced: true,
  state () {
    return {
      errorsListItems: [],
      errorsCounter: 0
    }
  },
  mutations: {
    setErrorsListItems (state, payload) {
      state.errorsListItems = payload
    },
    setErrorsCounter (state, payload) {
      state.errorsCounter = payload || 0
    }
  },
  getters: {
    errorsListItems (state) {
      return state.errorsListItems
    },
    hasErrorsListItems (state) {
      return state.errorsListItems && state.errorsListItems.length > 0
    },
    getErrorsCount (state) {
      return state.errorsCounter
    }
  },
  actions: {
    async retrieveErrorsCount (context, payload) {
      if (!context.rootGetters.isAuthenticated) {
        return 0
      }
      const filterQuery = (payload !== undefined && payload.filterQuery) ? `&filterbyurl=${payload.filterQuery}` : ''
      const host = context.rootGetters.getBackendApiUrl
      try {
        const response = await axios.get(`${host}/api/auto_writer_entries?isRead=false&notsuccessfullypublished=true&itemsPerPage=1${filterQuery}`, {
          headers: {
            Authorization: 'Bearer ' + context.rootGetters.token
          }
        })
        const responseData = response.data
        context.commit('setErrorsCounter', +responseData['hydra:totalItems'])
      } catch (error) {
        let errorMessage = error.message
        if (error.response) {
          errorMessage = error.response.data['hydra:description']
        }
        throw new Error(errorMessage || 'Nie udało się pobrać informacji o ilości błędów')
      }
    },
    async dismissErrorItem (context, payload) {
      const itemId = payload.id || null
      if (itemId) {
        const errorsListItems = context.getters.errorsListItems
        const host = context.rootGetters.getBackendApiUrl
        try {
          await axios.put(`${host}/api/auto_writer_entries/${itemId}`, {
            isRead: true
          }, {
            headers: {
              Authorization: 'Bearer ' + context.rootGetters.token
            }
          })
          context.commit('setErrorsListItems', errorsListItems.filter(item => item.id !== itemId))
          context.dispatch('retrieveErrorsCount', { filterQuery: '' })
        } catch (error) {
          let errorMessage = error.message
          if (error.response) {
            errorMessage = error.response.data['hydra:description']
          }
          throw new Error(errorMessage || 'Nie udało się zatwerdzić wpisu błędu')
        }
      }
    },
    async loadErrorsListItems (context, payload) {
      const page = payload.page || 1
      const filterQuery = (payload.filterQuery) ? `&filterbyurl=${payload.filterQuery}` : ''
      const host = context.rootGetters.getBackendApiUrl
      try {
        const response = await axios.get(`${host}/api/auto_writer_entries?isRead=false&notsuccessfullypublished=true&page=${page}${filterQuery}`, {
          headers: {
            Authorization: 'Bearer ' + context.rootGetters.token
          }
        })
        const responseData = response.data
        const errorsListItems = []
        const responseItems = responseData['hydra:member']
        for (const key in responseItems) {
          const errorItem = {
            id: responseItems[key].id,
            targetUrl: responseItems[key].targetUrl,
            entryTitle: responseItems[key].entrySeoTitle || responseItems[key].entryPreface || null,
            publishedError: responseItems[key].publishedError
          }
          errorsListItems.push(errorItem)
        }
        context.commit('setErrorsListItems', errorsListItems)
      } catch (error) {
        let errorMessage = error.message
        if (error.response) {
          errorMessage = error.response.data['hydra:description']
        }
        throw new Error(errorMessage || 'Nie udało się pobrać danych o błędnych publikacjach')
      }
    }
  }
}
